import React, { useEffect, useState } from "react";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import axiosInstance from "interceptor/axiosInstance";

function LotteryInvestment() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [selectedDate, setSelectedDate] = useState(null);
  const [investmentData, setInvestmentData] = useState([]);
  const [loading, setLoading] = useState();

  // Fetch data on component mount
  useEffect(() => {
    const fetchLotteryCards = async () => {
      try {
        const response = await axiosInstance.get(`${API_URL}/lottery/card/admin/list/`);
        setInvestmentData(response.data); // Set the fetched data
      } catch (error) {
        console.error("Error fetching lottery cards:", error);
        setError("Failed to fetch lottery cards.");
      } finally {
        setLoading(false);
      }
    };

    fetchLotteryCards();
  }, []);

  const handleDateClick = (date) => {
    // Toggle the visibility of the table for the clicked date
    setSelectedDate((prevDate) => (prevDate === date ? null : date));
  };

  const handleApiRequest = async (cardId, lotteryDate) => {
    console.log("Sending data:", { lottery: cardId, lottery_date: lotteryDate });

    const confirmUpdate = window.confirm("Are you sure you want to update this card?");

    if (confirmUpdate) {
      try {
        const response = await axiosInstance.post(`${API_URL}/lottery/card/result/update/`, {
          lottery: cardId,
          lottery_date: lotteryDate,
        });
        console.log("API response:", response.data);
        alert("Card update successful!");
      } catch (error) {
        console.error("API request failed:", error);

        if (error.response && error.response.data && error.response.data.error) {
          const errorMessage = error.response.data.error;
          alert(`Error: ${errorMessage}`); // Show the specific error message from the API
        } else {
          alert("Card update failed! Please try again.");
        }
      }
    } else {
      alert("Update canceled.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <h2>Lottery Investment Details</h2>

        {/* Buttons to Toggle Data Table */}
        <div style={{ marginBottom: "20px" }}>
          {investmentData.map((investment, index) => (
            <button
              key={index}
              onClick={() => handleDateClick(investment.investment_date)}
              style={{
                padding: "10px",
                marginRight: "10px",
                cursor: "pointer",
                backgroundColor:
                  selectedDate === investment.investment_date ? "#007bff" : "#f0f0f0",
                color: selectedDate === investment.investment_date ? "#fff" : "#000",
              }}
            >
              {investment.investment_date}
            </button>
          ))}
        </div>

        {/* Show Data Table for Selected Date */}
        {selectedDate && (
          <div>
            <h3>Investment Details for {selectedDate}</h3>
            <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ padding: "10px", textAlign: "left" }}>Card Name</th>
                  <th style={{ padding: "10px", textAlign: "left" }}>Card ID</th>
                  <th style={{ padding: "10px", textAlign: "left" }}>Total Investment</th>
                  <th style={{ padding: "10px", textAlign: "left" }}>Update</th>
                </tr>
              </thead>
              <tbody>
                {investmentData
                  .filter((investment) => investment.investment_date === selectedDate)
                  .flatMap((investment) =>
                    investment.cards.map((card, index) => (
                      <tr key={index}>
                        <td style={{ padding: "10px" }}>{card.lottery_card_name}</td>
                        <td style={{ padding: "10px" }}>{card.lottery_card_number}</td>
                        <td style={{ padding: "10px" }}>{card.total_investment}</td>
                        <td style={{ padding: "10px" }}>
                          <button
                            onClick={() =>
                              handleApiRequest(card.lottery_card_id, investment.investment_date)
                            }
                            style={{
                              background: "linear-gradient(90deg, #007bff, #0056b3)",
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              padding: "6px 12px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              transition: "transform 0.2s, box-shadow 0.2s",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.transform = "scale(1.05)";
                              e.target.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.2)";
                              e.target.style.background =
                                "linear-gradient(90deg, #0056b3, #004085)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.transform = "scale(1)";
                              e.target.style.boxShadow = "none";
                              e.target.style.background =
                                "linear-gradient(90deg, #007bff, #0056b3)";
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#updateTimerModal"
                          >
                            <span style={{ marginRight: "8px", fontSize: "1.2em" }}>✏️</span>
                            Update
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default LotteryInvestment;
